import { Injectable } from '@angular/core'
import { LogLevelEnum } from '../models/enums/log-level.enum'

@Injectable({ providedIn: 'root' })
export class LogService {
   public DONE = 'Done '
   public START = 'Start '
   public CONSTRUCTOR = 'constructor'

   public get packageName(): string {
      return this._packageName
   }

   public set packageName(value: string) {
      this._packageName = value
   }

   public get packageVersion(): string {
      return this._packageVersion
   }

   public set packageVersion(value: string) {
      this._packageVersion = value
   }

   public get enableLog(): boolean {
      return this._enableLog
   }

   public set enableLog(value: boolean) {
      this._enableLog = value
   }

   public debug(clazz: string, method: string, msg: string, obj?: any) {
      const type = LogLevelEnum.DEBUG
      const message = this.createLogMessage(type, clazz, method, msg, obj)
      if (this.logIsEnabled()) {
         console.log(message)
         if (obj) {
            console.log(obj)
         }
      }
   }

   public info(clazz: string, method: string, msg: string, obj?: any) {
      const type = LogLevelEnum.INFO
      const message = this.createLogMessage(type, clazz, method, msg, obj)
      if (this.logIsEnabled()) {
         console.log(message)
         if (obj) {
            console.log(obj)
         }
      }
   }

   public warning(clazz: string, method: string, msg: string, obj?: any) {
      const type = LogLevelEnum.WARNING
      const message = this.createLogMessage(type, clazz, method, msg, obj)
      if (this.logIsEnabled()) {
         console.warn(message)
         if (obj) {
            console.log(obj)
         }
      }
   }

   public error(clazz: string, method: string, msg: string, obj?: any) {
      const type = LogLevelEnum.ERROR
      const message = this.createLogMessage(type, clazz, method, msg, obj)
      if (this.logIsEnabled()) {
         console.error(message)
         if (obj) {
            console.log(obj)
         }
      }
   }

   private _JSON_LENGHT_LIMIT = 800
   private _packageVersion: string = ''
   private _packageName: string = ''
   private _enableLog: boolean = false

   private createLogMessage(type: LogLevelEnum, clazz: string, method: string, message: string, object: any): any {
      const s = this.getPrefix(type, clazz, method) + message
      return object ? s + ' ' + this.getJsonStringified(object) : s
   }

   private getPrefix(t: LogLevelEnum, clazz: string, method: string) {
      const d = new Date()
      const time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + '.' + d.getMilliseconds().toFixed()
      let c = 'undefinedClass'
      if (clazz) {
         c = clazz + '.' + method
      }

      const versionString = this._packageVersion.length > 0 ? '::v' + this._packageVersion : ''

      return '[' + this._packageName + versionString + '::' + time + '][' + LogLevelEnum[t] + '][' + c + '] '
   }

   private logIsEnabled() {
      return this.enableLog
   }

   private getJsonStringified(object: any) {
      let stringified = ''
      try {
         stringified = JSON.stringify(object)
      } catch (error) {
         stringified = 'The object cannot be stringified'
      }
      if (stringified.length > this._JSON_LENGHT_LIMIT) {
         return stringified.substring(0, this._JSON_LENGHT_LIMIT) + ' ...more'
      }
      return stringified
   }
}
